<template>
    <div>
        <div>
            <el-row type="flex" justify="space-between">
            <div>
                <el-input v-model="searchName" size="mini" style="width:160px" placeholder="课件名称" clearable></el-input>
                <el-button class="margin-l-sm" size="mini" @click="search">筛选</el-button>
            </div>
            <div><el-button type="primary" size="mini" icon="el-icon-plus" @click="showDialog">添加课件</el-button></div>
            </el-row>

        </div>
        <div class="margin-t-sm">

            <div class="table-content">

                <el-table
                        :header-cell-style="{padding:0}"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            label="名称">
                        <template slot-scope="scope">
                            <span>{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="下载次数">
                        <template slot-scope="scope">
                            <span>{{scope.row.download}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            property="created_at"
                            label="创建时间"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="状态" width="200">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status == 1" class="state-up"><i></i>上架</span>
                            <span v-if="scope.row.status == 0" class="state-down"><i></i>下架</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="操作">
                        <template slot-scope="scope">
                            <span>
                                <el-link v-if="scope.row.status == 1" type="primary" @click="setStatus(scope.row,scope.$index)">下架</el-link>
                                <el-link v-if="scope.row.status == 0" type="primary" @click="setStatus(scope.row,scope.$index)">上架</el-link>
                            </span> -
                            <span>
                                <el-popconfirm
                                        icon="el-icon-info"
                                        icon-color="red"
                                        title="删除后将不可恢复，确定删除吗？"
                                        @confirm="deleted(scope.row)"
                                >
                                  <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch"  style="padding-bottom: 10px">
                <span class="fl-r" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>

        </div>

        <el-dialog
                title="新增课件"
                :visible.sync="isShowDialog"
                :append-to-body="true"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :show-close="false"
                width="40%"
                center>
                <div>
                    <el-form ref="form" :model="form" label-width="120px">
                        <el-form-item label="课件名称:">
                            <el-input style="width:220px" size="mini" v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item label="选择文件：">
                            <select-file @onSelect="onSelect"></select-file>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-row type="flex" justify="end">
                        <el-button size="mini" @click="hideDialog">取 消</el-button>
                        <el-button size="mini" type="primary" @click="submit" :loading="isSubmit">确 定</el-button>
                    </el-row>
                </span>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import tools from "../../../utils/tools";
    import SelectFile from "../../../components/selectFile";
    export default {
        props:['classTotal'],
        components: { SelectFile},
        name: "file",
        data(){
            return{
                isSubmit:false,
                isShowDialog:false,
                total:0,
                loading:true,
                page:1,
                pageSize:10,
                name:'',
                tableData:[],
                id:0,
                searchName:'',
                form:{
                    name:'',
                    courseId:0,
                    fileId:0,
                },
            }
        },
        watch:{
            classTotal:function(val){

            }
        },
        methods:{
            ...mapActions('course',['getCourseFile','addCourseFile','setCourseFileStatus','delCourseFile']),
            search(){
                this.getCourseFileList()
            },
            getCourseFileList(){
                this.loading = true
                let data = {
                    id:this.id,
                    name:this.searchName,
                    page:this.page,
                    pageSize:this.pageSize
                }
                this.getCourseFile(data).then(res => {
                    if(res.ret == 0)
                    {
                        this.total = res.data.total
                        this.tableData = res.data.list
                        this.loading = false
                    }
                })
            },
            onSelect(file){
                this.form.fileId = file.id
            },
            submit(){
                this.isSubmit = true
                console.log(this.form)
                this.addCourseFile(this.form).then(res => {
                    if(res.ret == 0)
                    {
                        this.$message.success('新增成功！')
                        this.isSubmit = false
                        this.hideDialog()
                        this.getCourseFileList()
                        this.form.name  = ''
                        this.form.fileId  = 0
                    }


                })
            },
            showDialog(){
                this.isShowDialog = true
            },
            hideDialog(){
                this.isShowDialog = false
            },
            percentage(p){
                return tools.percentage(p)
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getCourseFileList()
            },
            setPage(page){
                this.page  = page
                this.getCourseFileList()
            },
            setStatus(row,rowIndex){
                let _this = this
                let data = {
                    id:row.id,
                    status:!row.status,
                }

                this.loading = true
                this.setCourseFileStatus(data).then(success => {
                    _this.tableData[rowIndex].status = success.data.status

                    _this.$message({
                        message: '设置成功',
                        type: 'success'
                    });
                    this.loading = false
                },error => {
                    _this.$message({
                        message: '设置失败',
                        type: 'error'
                    });
                    this.loading = false
                })
            },
            deleted(row, rowIndex){
                let _this = this
                this.loading = true
                console.log(row.id)
                this.delCourseFile(row.id).then(success => {
                    _this.getCourseFileList()
                    _this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                },error => {
                    _this.$message({
                        message: '删除失败',
                        type: 'error'
                    });
                })
            },
        },
        mounted() {
            this.id = this.$route.params.id
            this.form.courseId = this.$route.params.id
            this.getCourseFileList()
        }
    }
</script>

<style scoped>

</style>